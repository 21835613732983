import { useState, useEffect } from "react";
import React from "react";
import PopupWithForm from "../PopupWithForm/PopupWithForm";
import { AppContext } from "../non/contexts/AppContext";
import "./AddColback.css";

function AddColback({ isOpen, setIsSubmitForm, setIsInfoTooltipPopup, setTextTooltip }) {
	//контекст состояния загрузки и функции закрытия попапа
	const value = React.useContext(AppContext);

	//переменные состояния инпутов
	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");

	// Состояния для валидации
	const [phoneDirty, setPhoneDirty] = useState(false);
	const [nameDirty, setNameDirty] = useState(false);

	//состояния ошибки
	const [nameError, setNameError] = useState("Имя не может быть пустым");
	const [phoneError, setPhoneError] = useState("телефон не может быть пустым");

  const [error, setError] = useState();

	// Создаём функции для изменения состояния имени и валидации имени.
	const changeName = (e) => {
		setName(e.target.value);
		const re = /^([а-я]{1}[а-яё]{1,23}|[a-z]{1}[a-z]{1,23})$/;
		if (!re.test(String(e.target.value).toLowerCase())) {
			setNameError("Некоректное имя");
		} else {
			setNameError("");
		}
	};

	// Функци для изменения телефона и валидации телефона.
	const changeHandlerPhone = (e) => {
		setPhone(e.target.value);
		const re = /^\+?[78][-\(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/;
		if (!re.test(String(e.target.value).toLowerCase())) {
			setPhoneError("Некоректный номер телефона");
		} else {
			setPhoneError("");
		}
	};

	// Валидации всей формы и кнопки отправки
	const [formValid, setFormValid] = useState(false);

	useEffect(() => {
		if (phoneError || nameError) {
			setFormValid(false);
		} else {
			setFormValid(true);
		}
	}, [phoneError, nameError]);

	const blurHandler = (e) => {
		switch (e.target.name) {
			case "name":
				setNameDirty(true);
				break;
			case "phone":
				setPhoneDirty(true);
				break;
			default:
		}
	};

  //функция проверки ответа от сервера и записи текста ошибки
	function checkResponse(res) {
		if (res.ok) {
			return res.json();
		}
		res.json().then((e) => {
			setError(Object.values(e).toString());
		});
		return Promise.reject(`Ошибка: ${res.status}`);
	}

	// Функция для отправки формы
	const submitData = (e) => {
		e.preventDefault();
		value.closeAllPopups();
		return fetch("https://api-sopweb.ru/telegram", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ name, phone }),
		})
    .then(checkResponse)
			.then((res) => {
        setTextTooltip('Мы Вам обязательно перезвоним!')
				setIsInfoTooltipPopup(true);
        setIsSubmitForm(true);
				setPhone("");
				setName("");
			})
			//.then((result) => {
			//	setIsSubmitForm(true);
			//	setPhone("");
			//	setName("");
			//})
      .catch((err) => {
				console.log(err);
        console.log('hhh');
        
        setIsSubmitForm(false)
        setIsInfoTooltipPopup(true);
			});
	};

	return (
		<PopupWithForm
			title="Заказать звонок"
			name="colback-add"
			buttonSubmitText="Отправить"
			isOpen={isOpen}
			onClose={value.closeAllPopups}
			onSubmit={submitData}
			onDisabled={!formValid}
		>
			<fieldset className="popup__fieldset">
				{nameDirty && nameError && <div className="error1">{nameError}</div>}
				<label className="popup__label">
					<input
						id="name-input"
						name="name"
						placeholder="Ваше имя"
						autoComplete="off"
						className="popup__input popup__input_data_location popup__input-first"
						type="text"
						required
						minLength="2"
						maxLength="30"
						value={name}
						onChange={changeName}
						onBlur={(e) => blurHandler(e)}
					/>
					<span className="place-input-error popup__input-error"></span>
				</label>
				{phoneDirty && phoneError && <div className="error2">{phoneError}</div>}
				<label className="popup__label">
					<input
						id="phone-input"
						name="phone"
						placeholder="Ваш телефон"
						autoComplete="off"
						className="popup__input popup__input_data_location popup__input-first"
						type="phone"
						required
						minLength="2"
						maxLength="30"
						value={phone || ""}
						onChange={changeHandlerPhone}
						onBlur={(e) => blurHandler(e)}
					/>
					<span className="place-input-error popup__input-error"></span>
				</label>
			</fieldset>
		</PopupWithForm>
	);
}

export default AddColback;
